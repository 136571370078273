body {
  margin: 0;
  padding: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  background: #000000;
  scroll-behavior: smooth;
  height: 100%;
}
.background {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.star {
  position: absolute;
  border-radius: 50%;
}
.star-white {
    background-color: #ffffff;
    animation: twinkle 2s infinite ease-in-out;
}
.star-yellow {
    background-color: #ffd700;
    animation: twinkle 2s infinite ease-in-out;
}
@keyframes twinkle {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 1; }
}
.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 100vh; */
  padding: 2em;
  padding-top: 1em;
}
.tirc-company {
  text-align: center;
  padding: 2em;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  margin: 0.5em auto 1em auto;
  width: 80%;
  max-width: 800px;
}
.tirc-logo {
  width: 400px;
  margin-bottom: 20px;
}
.slogan {
  font-style: italic;
  margin-bottom: 10px;
}
hr {
  border: 1px solid #ffffff;
  margin: 20px auto;
  width: 50%;
}
.description {
  margin-bottom: 50px;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  padding: 20px;
}
.list {
  list-style-type: none;
  padding: 0;
}
.list li {
  margin: 10px 0;
}
.opt-desc {
  text-align: left;
}
.footer-nav {
  display: flex;
  justify-content: center;
  padding: 1em;
  background: rgba(0, 0, 0, 0.7);
  margin-top: 250px;
}
.footer-nav a {
  margin: 0 10px;
  text-decoration: none;
  color: white;
}
.footer-nav a:hover {
  text-decoration: underline;
}
.terms h1, .terms h2 {
  color: #baaea8;
  text-align: left;
}
.terms p {
  margin-bottom: 15px;
  text-align: left;
}
.aboutus p {
  text-align: left;
}
.centered-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-style {
  text-decoration: none;
  color: white;
}
.header-style {
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-weight: 100;
  font-size: 30px;
  /* border: 5px double; */
  /* border-color: rgb(76, 6, 108) rgb(198, 10, 198) rgb(255, 255, 255) #236dae; */
  padding: 20px;
  /* border-radius: 10px; */
  display: block;
}
.header-style-sm {
    display: none;
}
.logo-style {
  height: 70px;
  margin-right: 10px;
}
.header-text {
  position: relative;
  top: -20px;
  color: white;
}
@media only screen and (max-width: 400px) {
  .header-style {
      display: none;
  }
  .header-style-sm {
      display: block;
      font-family: 'Arial', 'Helvetica', sans-serif;
      font-weight: 100;
      font-size: 30px;
      padding: 20px;
  }
  .tirc-logo {
    width: 270px !important;
  }
} 
@media only screen and (max-width: 321px) {
  .tirc-logo {
    width: 220px !important;
  }
} 